import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaHandHoldingHeart, FaRestroom, FaHandsHelping } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Home.css";
import "../../App.css";
import { H1, H2, H3, H4, P, Span } from "../../components/Typography/index";
import HM from "../../assests/image/HM.png";
import dd2 from "../../assests/image/dd2.png";
import d6 from "../../assests/image/d6.jpg";
import gallery1 from "../../assests/image/gallary/gallery1.jpg";
import gallery2 from "../../assests/image/gallary/gallery2.jpg";
import gallery3 from "../../assests/image/gallary/gallery3.jpg";
import gallery4 from "../../assests/image/gallary/gallery4.jpeg";
import gallery5 from "../../assests/image/gallary/gallery5.jpg";
import heathcare from "../../assests/image/heathcare.jpg";
import health2 from "../../assests/image/health2.jpg";
import education2 from "../../assests/image/education2.jpg";
import Topheader from "../../Comman_Componets/Topheader/Topheader";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import { Image } from "antd";
// logo
import l1 from "../../assests/image/logo/l1.jpg";
import l2 from "../../assests/image/logo/l2.jpg";
import l3 from "../../assests/image/logo/l3.jpg";
import l4 from "../../assests/image/logo/l4.jpg";
import l5 from "../../assests/image/logo/l5.jpg";
import l6 from "../../assests/image/logo/l6.jpg";
import l7 from "../../assests/image/logo/l7.jpg";
import l8 from "../../assests/image/logo/l8.jpg";
import l9 from "../../assests/image/logo/l9.jpg";
import l10 from "../../assests/image/logo/l10.jpg";
import l11 from "../../assests/image/logo/l11.jpg";
import l12 from "../../assests/image/logo/l12.jpg";
import l13 from "../../assests/image/logo/l13.jpg";
import l14 from "../../assests/image/logo/l14.jpg";
import l15 from "../../assests/image/logo/l15.jpg";
import l16 from "../../assests/image/logo/l16.jpg";
import "antd/dist/antd.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home(props) {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3700,
    autoplaySpeed: 3700,
    cssEase: "linear",
  };

  const [visible, setVisible] = useState(false);
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="banner-area">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="banner-content text-center">
                <Span>Let’s contribute to make world a better place</Span>
                <H1>
                  Pragati Manav Seva Sansthan <br /> (PMSS) Raghogarh
                </H1>
              </div>
              {/* <div className="about-btn-area text-center">
                <Button className="theme_btn theme_btn_bg">
                  Donate Now<span></span>
                </Button>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="feature-area  pb-70">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="feature-item one">
                <i>
                  <FaRestroom />
                </i>
                <H4>Education & Skills Development</H4>
                <P>
                  Education and Skill Development program help child to deal
                  with life's challenges as it allows them to have a positive
                  attitude towards problems and solve problems creatively and
                  lays the foundation of his/her adulthood success later in
                  life.
                </P>
                {/* <Link to="/" className="feature-btn">
                  Join Now
                </Link> */}
              </div>
            </Col>
            <Col lg={4}>
              <div className="feature-item two">
                <i>
                  <FaHandsHelping />
                </i>
                <H4>Health Care & Awareness Programmes </H4>
                <P>
                  we are providing Or Medical health camp Body check-ups like
                  eye check-ups. which is one of the most ambitious programs
                </P>
                {/* <Link to="/" className="feature-btn">
                  Join Now
                </Link> */}
              </div>
            </Col>
            <Col lg={4}>
              <div className="feature-item three">
                <i>
                  <FaHandHoldingHeart />
                </i>
                <H4>Empowering Women and Creating Employment</H4>
                <P>
                  Our mission is to work with low-income families in rural areas
                  and create an environment where the locals take the initiative
                  in promoting development.
                </P>
                {/* <Link to="/" className="feature-btn">
                  Join Now
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-area">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="campus">
                <img src={HM} alt="About" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-content">
                <div className="section-title">
                  <Span className="sub-title">About us</Span>
                  <H2 className="sc">Who we are</H2>
                </div>
                <P>
                  Pragati Manav Seva Sansthan (PMSS) is a registered non-profit
                  in India (12A/80G tax exemption and FCRA certification) that
                  was set up in Our head office is in Raghogarh in Guna District
                  of Madhya Pradesh in 1999. Our mission is to work with
                  low-income families in urban and rural areas and create an
                  environment where the locals take the initiative in promoting
                  development. We aim to improve the quality of life through a
                  cross-sectorial approach focused on primary healthcare (for
                  women, children and senior citizens), education, livelihood
                  opportunities, sanitation and hygiene.
                </P>
                {/* <div className="about-btn-area">
                  <Button className="theme_btn theme_btn_bg">
                    Donate Now<span></span>
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="donations-area pt-100 ">
        <Container>
          <div className="section-title">
            <Span className="sub-title">Focus area of work</Span>
            <H2>Be the reason of someone smiles</H2>
          </div>
          <Row>
            <Col lg={6}>
              <div className="donation-item">
                <Link to="/Education" className="tags">
                  <div className="img">
                    <img src={education2} alt="Donation" />
                    {/* <Link to="/DonationDetails" className="common-btn">
                    Donate Now
                  </Link> */}
                  </div>
                  <div className="inner">
                    <div className="top">
                      <Link to="/Education" className="tags">
                        #Education
                      </Link>
                      <H3>Currently operating schools in three towns</H3>
                      <P>
                        1) Hindupat Public School Raghogarh (Nursery - XII CBSE)
                      </P>
                      <P>2) Hindupat Public School Aron (Nursery - X CBSE)</P>
                      <P>3) Hindupat Public School Jamner (Nursery - X CBSE)</P>
                      <P>
                        Our Goal is to provide quality education at low cost to
                        families living in rural areas.
                      </P>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="donation-item">
                <Link to="/Health" className="tags">
                  <div className="img">
                    <img src={heathcare} alt="Donation" />
                    {/* <Link to="/DonationDetails" className="common-btn">
                    Donate Now
                  </Link> */}
                  </div>
                  <div className="inner">
                    <div className="top">
                      <Link to="/Health" className="tags">
                        #Health Care
                      </Link>
                      <H3>Need help for treatment</H3>
                      <P>
                        We are working with optimised health care start up
                        founded by Dr. Ranu Dillan, Prof. form Harward Medical
                        School, in providing quality primary health care in
                        rural areas In the past, or organisation has provided
                        supplementary nutrition to childrens between age of 1-5
                        years in over 200 villages. During covid pandemic, we
                        has undertaken a waste survey to ensure vaccination and
                        used of masks and has supplied over 2 lacs masks in
                        rural areas.
                      </P>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="donation-item donation-item1">
                <Link to="/Livelihoods" className="tags">
                  <div className="img">
                    <img src={health2} alt="Donation" className="" />
                    {/* <Link to="/DonationDetails" className="common-btn">
                    Donate Now
                  </Link> */}
                  </div>
                  <div className="inner">
                    <div className="top">
                      <Link to="/Livelihoods" className="tags">
                        #Livelihoods
                      </Link>
                      <H3>1. Funding for family</H3>
                      <H3>2. SHG</H3>
                      <H3>3. ALF</H3>
                      <H3>4. Bio-Gas Plant</H3>
                      <H3>5. Swing Center</H3>
                      <P>
                        We are running over 180+ women self help groups (SHG)
                        where each group consists of 10 woman.
                      </P>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>

            <Col lg={6}>
              <div className="donation-item donation-item1">
                <Link to="/Energy" className="tags">
                  <div className="img">
                    <img src={d6} alt="Donation" className="skill-img" />
                    {/* <Link to="/DonationDetails" className="common-btn">
                    Donate Now
                  </Link> */}
                  </div>
                  <div className="inner">
                    <div className="top">
                      <Link to="/Energy" className="tags">
                        #Skill Development
                      </Link>
                      <H3>1. Raghogarh school of Arts</H3>
                      <H3>2. HCTI</H3>
                      <H3>2. HITI</H3>
                      <P>
                        Through Hindupath ITI, We are providing training to
                        youth in courses like, Electrician
                      </P>
                      <P>
                        Welding, Fire Fighting and Computer Diploma to provide
                        them better job opportunities.
                      </P>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="gallery-area pb-40">
        <Container>
          <div className="section-title">
            <Span className="Where-text">Where we work</Span>
            <P>
              We are currently working in state of Madhya Pradesh (MP) whihc is
              considered the heart of the India and is well known for its
              diversity and Culture. Our head office is located in the town of
              Raghogarh which has a population of over 65000 People. Raghogarh
              is also well known for having large public sector enterprises like
              GAS Authority of India Limited (GAIL), National Fertiliser Limited
              (NFL), Indian Oil Company Limited. We also have quality education
              institutes like Jaypee University engineering & technology and
              Satya Sai Vidhya vihar and Kendriya Vidhyalaya.
            </P>
          </div>
          <Row>
            <Col lg={4}>
              <div className="gallery-item">
                <Link to="/EventsGallery">
                  <Image
                    preview={{ visible: false }}
                    src={gallery1}
                    onClick={() => setVisible(true)}
                  />
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-item">
                <Link to="/EventsGallery">
                  <Image
                    preview={{ visible: false }}
                    src={gallery2}
                    onClick={() => setVisible(true)}
                  />
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-item">
                <Link to="/EventsGallery">
                  <Image
                    preview={{ visible: false }}
                    src={gallery3}
                    onClick={() => setVisible(true)}
                  />
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-item">
                <Link to="/EventsGallery">
                  <Image
                    preview={{ visible: false }}
                    src={gallery4}
                    onClick={() => setVisible(true)}
                  />
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-item">
                <Link to="/EventsGallery">
                  <Image
                    preview={{ visible: false }}
                    src={gallery5}
                    onClick={() => setVisible(true)}
                  />
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-item">
                <Link to="/EventsGallery">
                  <Image
                    preview={{ visible: false }}
                    src={dd2}
                    onClick={() => setVisible(true)}
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="App">
        <Container fluid>
          <Slider {...settings}>
            <div className="slit">
              <img src={l1} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l2} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l3} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l4} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l5} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l6} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l15} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l16} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              {" "}
              <img src={l7} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l8} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              {" "}
              <img src={l9} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l10} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l11} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l12} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l13} alt="lldd" className="slit-img" />
            </div>
            <div className="slit">
              <img src={l14} alt="lldd" className="slit-img" />
            </div>
          </Slider>
        </Container>
      </section>
    </>
  );
}
export default Home;
