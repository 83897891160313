import React from "react";
import { Formik, Field, Form } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";

const Form1 = () => {
  return (
    <Formik
      initialValues={{
        Name: "",
        Email: "",
        Subject: "",
        Number: "",
        TextArea: "",
      }}
      validate={(values) => {
        const errors = {};

        if (!values.Name) {
          errors.Name = "Required";
        }
        if (!values.Subject) {
          errors.Subject = "Required";
        }
        if (!values.TextArea) {
          errors.TextArea = "Required";
        }
        if (!values.Number) {
          errors.Number = "Required";
        } else if (!/^[6-9]\d{9}$/i.test(values.Number)) {
          errors.Number = "Invalid Number";
        }

        if (!values.Email) {
          errors.Email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)
        ) {
          errors.Email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        //Make API calls here

        setTimeout(() => {
          setSubmitting(false);
          alert(
            `Submitted Successfully ->  ${JSON.stringify(values, null, 2)}`
          );
        }, 2000);
      }}
      render={({ submitForm, isSubmitting, values }) => (
        <div className="fo-text">
          {/* <h5>Send Message</h5> */}
          <h1>Feel Free To Write Us Message.</h1>

          <Form>
            <Container>
              <Row>
                <Col md={6}>
                  <Field
                    label="Full Name"
                    name="Name"
                    type="text"
                    id="Name"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    type="email"
                    label="Email Address"
                    name="Email"
                    id="Email"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    type="text"
                    label="Subject"
                    name="Subject"
                    id="Subject"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    type="text"
                    label="Contact Number"
                    name="Number"
                    id="Number"
                    component={ReactstrapInput}
                  />
                </Col>
                <Col md={12}>
                  <Field
                    type="text"
                    label="Leave a comment here"
                    name="TextArea"
                    id="TextArea"
                    component={ReactstrapInput}
                    style={{ height: "100px" }}
                  />
                </Col>
                <Col xs={12}>
                  {/* <div className="f-bt"> */}
                  <Button type="submit" className="btn1-primary">
                    Submit{" "}
                  </Button>
                  {/* </div> */}
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      )}
    />
  );
};
export default Form1;
