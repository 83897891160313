import React from "react";
import Navbar from "../../Comman_Componets/Navbar/Navbar";
import { H2, P,H4,H5,H1, Span } from "../../components/Typography/index";
import {
  BsTelephoneOutbound,
  BsGlobe,
  BsChatLeftText,
  BsGeoAlt
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Form1 from "../Contact/Form1";
import "./Contact.css";
function Contact() {
  return (
    <>
      <Navbar />
      <div className="page-title-area title-bg-ty">
        <Container>
          <div className="title-item">
            {" "}
            <div className="tt"></div>
            <H2>Contact</H2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Span>Contact</Span>
              </li>
            </ul>
          </div>
        </Container>
      </div>

      <div className="contact-info-area pt-100 pb-60">
        <Container>
          <Row>
            <Col lg={6}>
            <div className="cnt">
                <H1 className="cony-tu">Contact Us</H1>
                {/* <H1>Contact Information</H1> */}
                <P>Do you have any questions?
                </P>
              </div>
              <div className="contacts d-flex align-items-center mb-30">
              <div className="contacts__icon mr-25">
               <i className="flaticon-phone-call"> <BsTelephoneOutbound /></i>
                 </div>
                <div className="contacts__text">
                <H4 className="semi-02-title">Phone Number</H4>
                <H5>+91 7544-262400 </H5>
                 </div>
                 </div>
                 <div className="contacts d-flex align-items-center mb-30">
              <div className="contacts__icon mr-25">
               <i className="flaticon-phone-call"> <BsChatLeftText /></i>
                 </div>
                <div className="contacts__text">
                <H4 className="semi-02-title">Email:</H4>
                <H5>pmssraghogarh@gmail.com</H5>
                 </div>
                 </div>
                 <div className="contacts d-flex align-items-center mb-30">
              <div className="contacts__icon mr-25">
               <i className="flaticon-phone-call"> <BsGlobe /></i>
                 </div>
                <div className="contacts__text">
                <H4 className="semi-02-title">Website</H4>
                <H5>http://www.pragatiraghogarh.com/</H5>
                 </div>
                 </div>
                 <div className="contacts d-flex align-items-center mb-30">
              <div className="contacts__icon mr-25">
               <i className="flaticon-phone-call"> <BsGeoAlt /></i>
                 </div>
                <div className="contacts__text">
                <H4 className="semi-02-title">Location:</H4>
                <H5>Pragati Manav Seva Sansthan Kothi Bagh Raghogarh, Dist. Guna
                  Madhya Pradesh, India Pin Code – 473226</H5>
                 </div>
                 </div>
            </Col>
            <Col lg={6}>
            <Form1 />
            </Col>
          </Row>
        </Container>
      </div>
     
    </>
  );
}

export default Contact;
